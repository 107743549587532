import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../static/Logo.svg';
import iconico from '../../static/favicon.ico';
import iconsvg from '../../src/assets/images/favicon.svg';

export default function SEO({ children, location, description, title, image}) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);
    return (
    <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
        <html lang="en" />
        <title>{title}</title>
        {/* Fav Icons */}
        <link rel="icon" type="image/svg+xml" href={iconsvg} />
        <link rel="alternate icon" href={iconico} />

        {/* Meta Tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <meta name="description" content={site.siteMetadata.description} />

        {/* Open Graph */}
        {location && <meta property="og:url" content={location.href} />}
        <meta property="og:image" content={image || logo} />
        <meta property="og:title" content={title} key="ogtitle" />
        <meta property="og:site_name" content={site.siteMetadata.title} key="ogsitename" />
        <meta property="og:description" content={description} key="ogdescription" />
        {children}
    </Helmet>
    );
}